<template>
  <a-space>
    <a-button type="primary" @click="confirmToEffective">生效</a-button>
    <a-button type="primary" @click="confirmToIneffective">失效</a-button>
  </a-space>
</template>

<script>
import { batchUpdateAgentsEffective } from '@/api/agent'

export default {
  name: 'UpdateAgentEffective',
  props: {
    selectedRowKeys: {
      type: Array,
      required: true
    }
  },
  methods: {
    warningSelectObjects() {
      this.$warning({
        title: '请选择操作对象',
        content: ''
      })
    },

    confirmToEffective() {
      const vm = this
      const ids = vm.selectedRowKeys
      if (ids.length === 0) {
        this.warningSelectObjects()
      } else {
        this.$confirm({
          title: '确定生效吗?',
          content: '',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            batchUpdateAgentsEffective(ids, true).then((res) => {
              if (res.code === 0) {
                // 告知父组件已完成
                vm.$emit('completed')
              }
            })
          }
        })
      }
    },

    confirmToIneffective() {
      const vm = this
      const ids = vm.selectedRowKeys
      if (ids.length === 0) {
        this.warningSelectObjects()
      } else {
        this.$confirm({
          title: '确定失效吗?',
          content: '',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            batchUpdateAgentsEffective(ids, false).then((res) => {
              if (res.code === 0) {
                // 告知父组件已完成
                vm.$emit('completed')
              }
            })
          }
        })
      }
    }
  }
}
</script>
